.info-tab-container{
    position: absolute;
    top: 100px;
    background-color: white;
    padding: 20px;
    width: 30vw;
    border-radius: 20px;
    opacity: 1;
    display: block;
    transition: all 0.5s ease-out;
    @media (min-width: 600px) and(max-width:768px){
        width: 50vw;
    }
    @media (min-width: 320px) and (max-width: 600px){
        display: none;
    }
    &.hidden{
        // top: -100%;
        opacity: 0;
    }
    .collapsible{
        font-size: 1.5em;
        font-weight: bold;
        background: none;
        cursor: pointer;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        color: #154722;
        font-family: CartonnageOT, "Cartonnage OT";
        &:after {
            content: '\25be';
            font-weight: bold;
            color: #154722;
            float: right;
            margin-left: 5px;
        }
        &.active:after {
            content: "\25B4";
            color: #154722;
          }
    }
    .content-desktop {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        .rank-holder{
            padding: 2px 0px 0px 0px;
            position: relative;
            .counter{
                position: absolute;
                display: inline-block;
                right: 4px;
                top: 0px;
            }
            .rank-bar-outer{
                position: relative;
                display: block;
                height: 13px;
                background-color: #707070;
                border-radius: 20px;
                .rank-bar-inner{
                    position: relative;
                    display: block;
                    height: 100%;
                    background-color: #fcd516;
                    border-radius: 20px;
                }
            }
        }
      }
}