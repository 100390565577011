@font-face {
  font-family: "Cartonnage OT";
  src: url(./resources/fonts/CartonnageOT-3da0ad112ecae4b0dbd1c1e98ac5b681.woff) format('woff');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.age-gate{
  font-family: "Cartonnage OT";
  /* padding: 0px 15px 15px 15px; */
  margin: 0px !important;
  height: 100vh;
  width: 100vw;
  background-image: url(./resources/age-gate-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
}

.age-gate-logo{
  margin: 0px 0px 80px 0px;
}

.age-gate-logo img{
  max-width: 350px;
}

.age-gate-form{
  margin: 0 auto;
  max-width: 500px;
  /* width: 100%; */
  /* background: #fff; */
  color: white;
  padding: 50px 20px;
  position: relative;
  text-align: center;
}

.age-gate-subheading{
  /* color: #015319; */
  font-size: 30px;
  margin: 25px auto 10px auto;
}

.age-gate-message{
  margin: 0 0 10px;
}

.age-back{
  padding: 20px 0px;
}

.ageYear{
  display: inline-block !important;
  position: relative;
  width: 100px !important;
  height: 100px !important;
  margin: 7px !important;
  text-align: center;
  color: #ffffff;
  border: 2px solid #ffffff !important;
  font-size: 50px !important;
  background: transparent !important;
  outline: none;
  background-size: contain !important;
  background-position: center;
  background-repeat: no-repeat !important;
  width: 60px !important;
  height: 60px !important;
  font-size: 35px !important;
}
.ageYear::placeholder{
  color: white;
}

.age-gate-submit{
  background: #ffd400!important;
  border-color: #ffd400;
  border-radius: 0;
  font-size: 35px!important;
  padding: 5px 25px!important;
  font-family: "Cartonnage OT";
  font-weight: 200 !important;
  color: #015319!important;
}

.age-gate-footer{
  position: fixed;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.age-gate-footer img{
  max-width: 350px;
}