.tweet-container{
        position: absolute;
        margin: 0 auto;
        width: 100%;
        height: calc(100vh - 100px);
        overflow-y: scroll;
        top: 100px;
        color: #333;
        opacity: 1;
        transition: all .5s ease-out;
        &.hidden{
            top: -100%;
            opacity: 0;
        }
        .close-mason{
            position: fixed;
            top: 120px;
            left: 95vw;
            cursor: pointer;
            z-index: 4;
        }
        .tweet-content{
            font-size: 1.5em !important;
        }
        .tweet-block{
            cursor: pointer;
        }
        // background: #419be0;
}