.terms-container{
    position: absolute;
    width: 80vw;
    height: 80vh;
    background: #fdd300;
    padding: 20px;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
    color: #154722;
    &.close{
        display: none;
    }
    .content{
        height: 80vh;
        overflow-x: scroll;
    }
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
}