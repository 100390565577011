
.clickable-element{
    cursor: pointer;
}
.map-container{
    top: 0px;
    margin: 0 auto;
    left: 50% !important;
    transform: translatex(-50%);
    display: inline-block;
    left: 0px;
    position: absolute;
    transition: all 0.5s ease-out;
    opacity: 1;
    @media (min-width: 320px) and (max-width: 768px){
        left: 0 !important;
        transform: translateX(0px);
    }
    &.hidden{
        top: -100%;
        opacity: 0;
    }
    svg{
        width: auto;
        height: 100vh;
        position: relative;
        @media (min-width: 320px) and (max-width: 768px) {
            left: auto;
            width: 180vw;
            height: auto;
            left: -68%;
            transform: translateX(50%);
            margin: 0 auto;
        }
        .prov-circle{
            cursor: pointer;
            transform-box: fill-box;
        }
        #newtown-pin{
            transform: translate(434.899, 1058.96) rotate(-8deg);
            &hover{

            }
        }
    }
}
