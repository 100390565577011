.tweet-block{
    background-color: white;
    padding: 20px;
    box-shadow: 0px -2px 9px 0px rgba(0,0,0,0.21);
    // -webkit-box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 21%);
    -moz-box-shadow: 0px -2px 9px 0px rgba(0,0,0,0.21);
    text-align: left;
    color: black;
    font-weight: 600;
    margin: 10px;
    // margin: 10px auto;
    max-width: 550px;
    .tweet-header{
        position: relative;
        display: block;
        width: 100%;
        padding: 10px 0px 0px 5%;
        .tweet-name{
            display: inline-block;
            width: 100%;
            height: 100%;
            vertical-align: middle;
            text-align: left;
            .tweet-icon-img{
                width: 60px;
                display: inline-block;
                position: relative;
                vertical-align: middle;
                padding: 0px 10px 0px 0px;
            }
            .tweet-name-inner{
                display: inline-block;
                position: relative;
            }
            .vericon{
                width: 15px;
                padding: 0px 5px;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }
    .tweet-content{
        padding: 15% 5%;
        font-weight: 700;
        // word-break: break-all;
        font-size: 80%;
        width: 85%;
    }
    .tweet-footer{
        text-align: center;
        .tweet-icon{
            display: inline-block;
            width: 25%;
            .icon-image{
                width: 25%;
                max-width: 20px;
                margin: 0 auto;
            }
        }
    }
}