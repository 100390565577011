@import "./variables.scss";

.logo{
    height:14px;
    padding: 20px 0px;
}
.col2{
    display: inline-block;
    white-space: normal;
    vertical-align: middle;
    .siya{
        width: 200px;
        @media (min-width: 320px) and (max-width: 768px) {
            width: 150px;
        }
    }
}
.btn-cta {
    position: relative;
    display: inline-block;
    background: $green;
    padding: 10px 15px;
    margin: 15px;
    border-radius: 10px;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
}
.home-container{
    height: 100vh;
    overflow: scroll;
    padding: 30px;
    // max-width: 1500px;
    // margin: 0 auto;
    .no {
        position: absolute;
        bottom: 100px;
        right: 20px;
        z-index: 3;
        img{
            width:120px
        }
    }
    .welcome{
        font-size: 50px;
        font-weight: 500;
        margin: 0px;
        padding: 0px;
        @media (min-width: 320px) and (max-width: 768px) {
            font-size: 2.2em;
        }
    }
    .welcome-logo{
        height: 25px;
        width: 100%;
        @media (min-width: 320px) and (max-width: 768px) {
            height: 18px;
        }
    }
    .scroll-down{
        position: absolute;
        bottom: 100px;
        width: 30px;
        height: 30px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        @media (min-width: 320px) and (max-width: 768px) {
            left: 90%;

        }
        .scroll-icon{
            width: 100%;
        }
    }
    .landing-container{
        display: block;
        position: relative;
        white-space: nowrap;
        height: 100vh;
        padding: 30px;
        max-width: 1500px;
        margin: 0 auto;
        @media (min-width: 320px) and (max-width: 768px) {
            display: inline-block;
            padding: 0px;
        }
        .col2{
            &.intro{
                height: auto !important;
                width: 40%;
                text-align: center;
                top: -60px;
                position: relative;
                @media (min-width: 320px) and (max-width: 768px) {
                    top: 0px;
                    width: 100%;
                    padding-top: 100px;
                    display: block;
                }
            }
            &.tree{
                width: 60%;
                height: 100%;
                padding-top: 120px;
                @media (min-width: 320px) and (max-width: 768px) {
                    width: 100%;
                    padding-top: 20px;
                    display: inherit;
                    height: 60%;
                }
            }
            .tag{
                height: auto;
                width: 90%;
                position: relative;
                display: block;
                text-align: center;
                padding: 0px;
                margin: 0 auto;
            }
            p{
                padding: 0px 20px;
            }
        }
    }
    .info-container{
        height: 80%;
        white-space: nowrap;
        padding: 30px;
        max-width: 1500px;
        margin: 0 auto;
        @media (min-width: 320px) and (max-width: 768px) {
            display: inline-block;
            padding: 0px;
        }
        .info-left{
            height: auto !important;
            width: 60%;
            position: relative;
            display: inline-block;
            @media (min-width: 320px) and (max-width: 768px) {
                width: 100%;
                padding-top: 120px;
                display: block;
            }
            .info-img{
                width: 80%;
                @media (min-width: 320px) and (max-width: 768px) {
                    width: 100%;
                    height: auto;
                    // padding-top: 120px;
                    display: block;
                }
            }

        }
        .info-right{
            padding: 20px;
            text-align: left;
            width: 40%;
            display: inline-block;
            position: relative;
            .inline-tagline{
                height: 10px;
            }
            @media (min-width: 320px) and (max-width: 768px) {
                width: 100%;
                padding: 40px 0px;
                display: block;
                text-align: center;
            }

            .btn-cta {
                position: relative;
                display: inline-block;
                background: $green;
                padding: 10px 15px;
                margin: 15px;
                border-radius: 10px;
                color: white;
                text-transform: uppercase;
                font-weight: 700;
                text-decoration: none;
            }
            p{
                padding: 0px 20px;
            }
        }
    }
    .buy-container{
        height: 100vh;
        white-space: nowrap;
        padding: 30px;
        max-width: 1500px;
        margin: 0 auto;
        @media (min-width: 320px) and (max-width: 768px) {
            display: inline-block;
            padding: 80px 0px 0px 0px;
        }
        .info-left{
            height: auto !important;
            text-align: left;
            width: 40%;
            position: relative;
            display: inline-block;
            p{
                padding-left: 20%;
                @media (min-width: 320px) and (max-width: 768px) {
                    padding: 0px;
                }
            }
            .button-containers{
                    padding-left: calc(20% - 15px);
                    @media (min-width: 320px) and (max-width: 768px) {
                        padding: 0px;
                    }
            }
            @media (min-width: 320px) and (max-width: 768px) {
                width: 100%;
                text-align: center;
                display: block;
            }

        }
        .info-right{
            padding: 20px;
            // text-align: left;
            width: 60%;
            display: inline-block;
            position: relative;
            text-align: center;
            @media (min-width: 320px) and (max-width: 768px) {
                width: 100%;
                padding: 0px;
                display: block;
            }
            .info-img{
                // height: 80%;
                height: 80%;
                width: auto;
                max-height: 600px;
                @media (min-width: 320px) and (max-width: 768px) {
                    height: auto;
                    width: 80%;
                    text-align: center;
                    margin: 0 auto;
                    height: auto;
                    display: block;
                    max-height: none;
                }
            }
        }
    }

}

