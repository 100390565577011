.popup-container{
    position: absolute;
    display: block;
    width: 300px;
    height: auto;
    background-color: white;
    z-index: 3;
    border-radius: 10px;
    @media (min-width: 320px) and (max-width: 768px) {
        width: 250px;
        left: 50% !important;
        transform: translateX(-50%);
        top: 20vh !important;
        /* transform: translate(-50%, -50%); */
    }
    &.hidden{
        display: none;
    }
    .btn-cta{
        left: 50%;
        transform: translateX(-50%);
        margin: 15px 0px;
    }
    .popup-tweet{
        padding: 10px;
        .popup-tree-title{
            text-align: center;
            font-weight: 300;
            font-family: 'Cartonnage OT';
            font-size: 25px;
            margin: 5px;
        }
        .popup-tree{
            width: 100%;
        }
        .tweet-block{
            box-shadow: none;
            padding: 0px;
        }
    }
    .close-popup{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
}