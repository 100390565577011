.info-tab-container-mobi{
    width: 95vw;
    height: calc(100vh - 97px);
    background-color: #f7f5f5;
    top: 75vh;
    position: absolute;
    display: block;
    left: 10px;
    border-radius: 30px 30px 0px 0px;
    z-index: 2;
    @media only screen and (min-width: 600px) {
        display: none;
    }
    &.hidden{
        // top: -100%;
        opacity: 0;
        display: none;
    }
    .grab{
        position: absolute;
        height: 30px;
        display: block;
        width: 100%;
        .line{
            position: relative;
            width: 35px;
            border-radius: 20px;
            margin: 15px auto;
            height: 5px;
            background-color: #c4c4c4;
        }
    }
    .map-icons{
        position: relative;
        display: block;
        width: 100%;
        top: 30px;
        margin: 0px auto;
        padding: 10px 0px;
        text-align: center;
        a{
            position: relative;
            display: inline-block;
            width: 25%;
            margin: 5px;
            .iconImage{
                width: 100%;
                display: inline-block !important;
                margin: 0 auto;
                position: relative;
                display: block;
                &.true{
                    transform: scale(1.08);
                }
            }
        }
    }
    .pull-up-content{
        position: relative;
        display: block;
        height: 65vh;
        overflow-y: scroll;
        top: 30px;
        .collapsible{
            font-size: 1.5em;
            font-weight: bold;
            background: none;
            cursor: pointer;
            padding: 10px 20px;
            width: 100%;
            border: none;
            text-align: left;
            outline: none;
            color: #154722;
            opacity: 1;
            font-family: CartonnageOT, "Cartonnage OT";
            transition: all 0.2s ease-out;
            &.hide{
                height: 0px;
                opacity: 0;
            }
            &:focus:not(:focus-visible) {
                outline: none;
                box-shadow: none;
              }
            &:after {
                content: '\25be';
                font-weight: bold;
                color: #154722;
                float: right;
                margin-left: 5px;
            }
            &.active:after {
                content: "\25B4";
                color: #154722;
              }
        }
        .tree-tab{
            position: relative;
            display: block;
            max-height: 500px;
            padding-bottom: 160px;
            overflow: hidden;
            transition: all 0.2s ease-out;
            &.hide{
                opacity: 0;
                height: 0;
            }
            .tree{
                position: relative;
                display: inline-block;
                width: 50%;
                text-align: center;
                .treeLoc{
                    margin-bottom: 0;
                }
                img{
                    width: 90%;
                    margin: 0 auto;
                }
            }
        }
        .rank-tab {
            position: relative;
            display: block;
            width: 80%;
            margin: 0px auto;
            padding: 20px;
            background: #ffff;
            border-radius: 10px;
            max-height: 500px;
            overflow: hidden;
            opacity: 1;
            transition: all 0.2s ease-out;
            &.hide{
                opacity: 0;
                height: 0;
            }
            p{
                font-size: 1.5em;
                font-weight: bold;
                margin: 0px 0px 10px 0px;
                font-family: CartonnageOT, "Cartonnage OT";
            }
            .rank-holder{
                padding: 2px 0px 0px 0px;
                position: relative;
                .counter{
                    position: absolute;
                    display: inline-block;
                    right: 4px;
                    top: 0px;
                }
                .rank-bar-outer{
                    position: relative;
                    display: block;
                    height: 13px;
                    background-color: #707070;
                    border-radius: 20px;
                    .rank-bar-inner{
                        position: relative;
                        display: block;
                        height: 100%;
                        background-color: #fcd516;
                        border-radius: 20px;
                    }
                }
            }
          }
    }
}