@import "./variables.scss";

.nav-container {
  background-color: $yellow;
  width: 100vw;
  height: 100px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3;
  .logo-container {
    position: absolute;
    left: 20px;
    text-align: left;
    top: 50%;
    transform: translateY(-50%);
  }
  #menu__toggle {
    opacity: 0;
  }

  #menu__toggle:checked ~ .menu__btn > {
    span {
      transform: rotate(45deg);
      &::before {
        top: 0;
        transform: rotate(0);
      }
      &::after {
        top: 0;
        transform: rotate(90deg);
      }
    }
  }
  #menu__toggle:checked ~ .menu__box {
    visibility: visible;
    right: 0;
  }

  .menu__btn {
    display: block;
    align-items: center;
    position: absolute;
    top: calc(50% - 8px);
    right: 20px;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
    padding-top: 8px;
  }

  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;

    width: 100%;
    height: 2px;

    background-color: #154722;

    transition-duration: 0.25s;
  }
  .menu__btn > span::before {
    content: "";
    top: -8px;
  }
  .menu__btn > span::after {
    content: "";
    top: 8px;
  }

  .menu__box {
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    right: -100%;
    width: 300px;
    height: 100%;
    margin: 0;
    padding: 80px 0;
    list-style: none;
    background-color: #fcd516;
    box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.2);
    transition-duration: 0.25s;
  }

  .menu__item {
    display: block;
    padding: 12px 24px;

    color: #154722;

    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 600;

    text-decoration: none;

    transition-duration: 0.25s;
  }
  .menu__item:hover {
    // background-color: #cfd8dc;
    color: #fff;
  }
}
