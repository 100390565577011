.new-form {
  width: 100%;
  height: 80vh;
  border: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  @media (min-width: 480px) and (max-width: 768px) {
      height: 75vh;
  }
}

.lightbox {
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;
  &.open {
    display: block;
  }
  &.close {
    display: none;
  }
  .form-container {
    position: relative;
    background-color: #fdd300;
    width: 763px;
    margin: auto auto;
    height: 100vh;
    display: block;
    // margin-top: 10vh;
    @media (max-width: 480px) {
      width: auto;
      height: 100vh;
      margin: 0px;
    }
    @media (min-width: 480px) and (max-width: 768px) {
      margin: 0 auto;
      margin-top: 5vh;
      width: 80vw;
      height: 90vh;
    }
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }
    .logo-2 {
      padding: 20px;
      .throw-logo {
        position: relative;
        display: block;
        margin: 0 auto;
        width: 250px;
      }
    }
    .tagline {
      img {
        width: 95%;
        position: relative;
        margin: 0 auto;
        display: block;
        @media (min-width: 320px) and (max-width: 768px) {
          width: 95%;
          padding-top: 40px;
        }
      }
    }
    .cap-img-container {
      width: 48%;
      height: 100%;
      background-image: url(../resources/home/form_img.png);
      background-position: bottom right;
      background-size: cover;
    //   .cap-img {
    //     width: 100%;
    //     @media (min-width: 320px) and (max-width: 768px) {
    //       display: none;
    //     }
    //   }
      @media (min-width: 320px) and (max-width: 768px) {
        display: none;
      }
    }
    .throw-form {
      width: 52%;
      text-align: left;
      color: #154722;
      @media (min-width: 320px) and (max-width: 768px) {
        width: 100%;
        display: block;
      }
      form {
        padding: 0px 30px;
        .submit-btn {
          color: white;
          width: 150px;
          border-radius: 15px;
          background-color: #154722;
        }
        input,
        select,
        textarea {
          width: 100%;
          height: 30px;
          padding: 0px 5px !important;
          /* margin: 0px; */
          margin: 5px 0px 10px;
          border: 0px;
          border-radius: 8px;
          color: #154722;
          outline: none;
        }
        textarea {
          height: auto !important;
        }
      }
    }
  }
}
