.footer-container{
    background-color: white;
    width: 100vw;
    height: 130px;
    position: relative;
    display: block;
    bottom: 30px;
    left: -30px;
    .upper{
        position: relative;
        height: 80px;
        .logo-container {
            position: absolute;
            left: 20px;
            text-align: left;
            top: 50%;
            width: 300px;
            transform: translateY(-50%);
            @media (min-width: 320px) and (max-width: 480px) {
                width: 200px;
                }
            img{
                width: 100%;
            }
        }
        .social-container {
            position: absolute;
            right: 20px;
            height: 100%;
            min-width: 120px;
            .hash {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                right: 120px;
                @media (min-width: 320px) and (max-width: 480px) {
                    display: none;
                }
            }
            .icons {
                position: absolute;
                right: 0px;
                top: 50%;
                transform: translateY(-50%);
                .icon{
                    padding: 0px 5px;
                }
            }
        }
    }
    .lower{
        position: relative;
        height: 60px;
        .no-footer{
            position: absolute;
            left: 0px;
            padding: 0px 20px;
            img{
                height: 50px;
                position: relative;
                left: 0px;
                text-align: left;
            }
        }
        .covid-footer{
            position: absolute;
            right: 0px;
            padding: 0px 20px;
        }
    }
}