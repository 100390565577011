.slick-prev:before, .slick-next:before{
    color: #154722 !important;
}

.carousel-lightbox{
    margin: 0 auto;
    padding: 40px;
    width: 60%;
    color: #333;
    position: relative;
    // margin-top: 15%;
    /* top: 50%; */
    display: block;
    .tweet-block{
        margin: 10px auto;
        .tweet-content{
            font-size: 1.8em;
            padding: 5%;
        }
    }
    opacity: 1;
    transition: all 0.5s ease-out;
    &.hidden{
        top: -100%;
        opacity: 0;
    }
    /* transform: translateY(-50%); */
    @media (min-width: 320px) and (max-width: 480px) {
        width: auto;
        padding-top: 30px;
    }
    .close-carousel{
        right: 0px;
        top: 10px;
        position: absolute;
        cursor: pointer;
    }
    .tweet-share{
        width: 15px;
        display: inline-block;
        vertical-align: middle;
    }
    .btn-cta{
        left: 50%;
        transform: translateX(-50%);
        margin: 15px 0px;
    }
    .btn-shadow{
        width: 150px;
        margin: 0 auto;
    }
}