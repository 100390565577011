.shade-container{
    padding-top: 100px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    .lockup{
        position: absolute;
        width: 180px;
        display: block;
        left: 20px;
        top: 85vh;
        @media (min-width: 320px) and (max-width: 768px) {
            display: none;
        }
        .lockup-img{
            width: 100%;
            &.top{
                width: 60%;
                margin: 0 auto;
                position: relative;
                display: block;
            }
        }
    }
    // @media (min-width: 320px) and (max-width: 600px){
    //     overflow-y: hidden;
    // }
    // &.hidden{
    //     overflow-y: scroll;
    // }
}
